import React, { useEffect, useState, useContext } from 'react'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'
import VisitorLayout from 'src/components/layout/VisitorLayout'
import Heading from 'src/components/typography/Heading'
import { OutlinedButton } from 'src/components/buttons/Primary'
import heroRegisterTeacher from 'src/images/register/hero-register-teacher.jpg'
import useLocalizedRoutes from 'src/hooks/useLocalizedRoutes'
import { useQueryParam, StringParam } from 'use-query-params'
import {Context as ProfileContext} from '../../context/ProfileContext';
import {useVisitorApi} from "../../hooks/useApi";
import Success from "../../components/forms/Success";

export default function ConfirmEmailTeacher(props) {
    const { t } = useTranslation();
    const [token, setToken] = useQueryParam("verification_token", StringParam);
    const { state : { accessToken } } = useContext(ProfileContext);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { home, addTeacherDetails, verifyEmailTeacher } = useLocalizedRoutes();
    const { verifyEmail, getMe} = useVisitorApi();
    const {state: {profile}} = useContext(ProfileContext);
    const {location} = props;
    const {resendVerification} = useVisitorApi();
    const [successMsg, setSuccessMsg] = useState(false);

    async function resendEmailVerification() {
        const redirectUrl = location.origin + verifyEmailTeacher;
        const res = await resendVerification(redirectUrl);
        if (res.status === 200) {
            setSuccessMsg(true);
            setTimeout(() => setSuccessMsg(false), 3000);
        }
    }

    async function verify() {
        try {
            const res = await verifyEmail({'verification_token': token})
            if (res.status === 200) {
                setIsSuccess(true);
            }
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        verify().then(() => {
            getMe();
        });
    }, [token]);

    return (
        <VisitorLayout {...props} hero={heroRegisterTeacher} title={t('teacher.confirmEmail.title')}>
            {profile ?
            <div className="px-6 lg:px-0 pt-12 lg:pt-24">
                {(profile.email_verified_at || (isSuccess && !isLoading)) && (
                    <Heading type='head-title-wrap'>{t('teacher.verifyEmail.success.title')}</Heading>
                )}
                {(!profile.email_verified_at && (!isSuccess && !isLoading)) && (
                    <Heading type='head-title-wrap'>{t('teacher.verifyEmail.error.title')}</Heading>
                )}
                <div className="py-10">
                    {(profile.email_verified_at || (isSuccess && !isLoading)) && (
                        <p>{t('teacher.verifyEmail.success.body')}</p>
                    )}
                    {(!profile.email_verified_at && (!isSuccess && !isLoading)) && (
                        <p>{t('teacher.verifyEmail.error.body')}</p>
                    )}
                </div>
                {(profile.email_verified_at || (isSuccess && !isLoading)) && (
                    <OutlinedButton
                        onClick={() => navigate(addTeacherDetails)}>{t('teacher.verifyEmail.success.button')}</OutlinedButton>
                )}
                {(!profile.email_verified_at && (!isSuccess && !isLoading)) && (
                    <OutlinedButton
                        onClick={() => resendEmailVerification()}>{t('general.verifyEmailResend')}</OutlinedButton>
                )}
                {successMsg && <Success className="">{t('general.verifyEmail', {email: profile.email})}</Success>}
            </div> :
                <>
                    <Heading type='head-title-wrap'>{t('teacher.verifyEmail.error.title')}</Heading>
                    <div className="py-10">
                        {!isSuccess && !isLoading && (
                            <p>{t('general.accountNotfound')}</p>
                        )}
                    </div>
                    <OutlinedButton
                        onClick={() => navigate(home)}>{t('teacher.verifyEmail.error.button')}</OutlinedButton>
                </>
            }
        </VisitorLayout>
    )
}
